@font-face {
  font-family: 'Montserrat';
  src: url('../../../assets/fonts/montserrat/fonts/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../../assets/fonts/montserrat/fonts/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../../assets/fonts/montserrat/fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../../assets/fonts/montserrat/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400; // normal
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../../assets/fonts/montserrat/fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../../assets/fonts/montserrat/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../../assets/fonts/montserrat/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../../assets/fonts/montserrat/fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../../assets/fonts/montserrat/fonts/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
