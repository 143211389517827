.responsive-card-image {
  width: 100%;
  max-height: 160px;
  object-fit: contain;
}
.actionLink {
  border-bottom: 1px solid currentColor;
  padding-bottom: 0.5px;
  display: inline-block;
  transition: transform 0.2s ease-in-out, font-weight 0.2s ease-in-out;
  font-weight: normal;
}

.actionLink:hover {
  transform: scale(1.05); /* 5% larger when hovered */
  font-weight: bolder;
}
