/*================================================================================
	
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import '~flatpickr/dist/flatpickr.min.css';

.dot {
  height: 6px;
  width: 6px;
  background-color: #5e5873;
  border-radius: 50%;
  display: inline-block;
  margin: 10px;
}

.customDialog {
  min-width: 900px;
}

.vertical-line {
  width: 1px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 50%;
  pointer-events: none;
}
